import { get } from './api'
import {
  __,
  assoc,
  dissoc,
  filter,
  head,
  includes,
  indexBy,
  map,
  pipe,
  prop,
  propEq,
  length,
  last,
  prepend, propOr
} from 'ramda'
import safe from 'crocks/Maybe/safe'
import {isArray, isObject} from "crocks/predicates"

export const fetchIt = () => {
  return get( '/wp/v2/marine-type?per_page=100' )
}

const getTopParents = filter( propEq( 0, 'parent' ) )

const findSubGroups = items => {
  return filter( pipe( prop( 'parent' ), includes( __, map( prop( 'id' ), getTopParents( items ) ) ) ), items )
}

const addParentInfo = items => {
  const indexedItems = indexBy( prop( 'id' ), items )
  return map( item => {
    if (item.parent) {
      item.parentName = indexedItems[item.parent]['name']
    }
    return item
  }, items )
}
export const getSubGroups = pipe( addParentInfo, findSubGroups )

const idIs = propEq( __, 'id' )

export const getChildren = (item, items, allItems) => {
  const children = filter( propEq( item.id, 'parent' ), allItems )
  return map( (v) => idIs( item.id, v ) ? assoc( 'children', children, v ) : v, items )
}

export const removeChildren = (item, items, allItems) => {
  return map( (v) => idIs( item.id, v ) ? dissoc( 'children', v ) : v, items )
}

export const orderByParent = items => {
  let parent = 0
  let output = []

  for (let i = 0; i < length( items ); i++) {
    output.push( head( filter( propEq( parent, 'parent' ), items ) ) )
    parent = prop( 'id', last( output ) )
  }
  return output
}

export const getHierachy = (item, allItems) => {
  let output = []
  output.push(item)
  while(head(output).parent) {
    output = prepend(allItems[head(output).parent], output)
  }

  return output
}

export const isGrandchild = (itemType, items) => {
  const safeArray = safe(isArray)
  const maybeLast = pipe(last, safe(isObject))

  return safeArray(items)
      .map(orderByParent)
      .chain(maybeLast)
      .map(propOr(-1, 'id'))
      .option(-1) === itemType;
}

export const getColor = item => item.acf.color