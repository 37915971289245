import {textColor} from "../lib/settings";
import {useParams} from "react-router-dom";
import {useStoreState} from "easy-peasy";
import {filter, head, prop, propEq, replace} from "ramda";
import {MarineLifeGallery} from "./MarineLifeGallery";
import {useEffect} from "react";
import {sendPageView} from "../lib/google-analytics";


export const Page = ({page}) => {

    const {slug} = useParams()
    const pages = useStoreState(prop('pages'))
    const thePage = page || head(filter(propEq(slug, 'slug'), pages))

    const content = thePage ? replace(/class="([^"]*?wp-image)/g, 'class="default-image $1', thePage.content.rendered) : ''

    useEffect(() => {
        sendPageView()
    }, [page])

    return <>
        {thePage ?
            <>
                <div
                    className={'marine-group'}
                    dangerouslySetInnerHTML={{__html: content}}
                    style={{padding: '10px', fontSize: '18px', color: textColor}}
                />
                <MarineLifeGallery marineLife={thePage}/>
            </>
            : ''}
    </>
}