import {Layout, Space} from 'antd'
import './Header.css'
import {useEffect, useRef} from 'react'
import {head, pipe, prop, split, last, toUpper} from 'ramda'
import {useStoreActions, useStoreState} from 'easy-peasy'

const {Header: HeaderAntD} = Layout

export const Header = () => {
    const ref = useRef()

    const title = useStoreState(prop('title'))
    const tagline = useStoreState(prop('tagLine'))
    const loadSiteInfo = useStoreActions(prop('loadSiteInfo'))

    const display = useStoreState(prop('display'))

    useEffect(() => {
        ref.current.scrollIntoView({behavior: 'smooth'})
    }, [display])

    useEffect(() => {
        document.title = toUpper(title)
    }, [title]);


    useEffect(() => {
        loadSiteInfo()
    })

    const className = 'header'

    return (
        <>
            <span ref={ref}>
                <HeaderAntD className={className}>
                    <Space.Compact direction={'vertical'}>
                        {title && <span className={'main'}>{toUpper(title)}</span>}
                        <span className={'secondary'}>{tagline}</span>
                    </Space.Compact>
                </HeaderAntD>
            </span>
        </>
    )
}
