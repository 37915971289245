import {List} from "antd";
import {MarineLifeMenu} from "../components/MarineLifeMenu";
import {
    curryN,
    filter,
    identity,
    length,
    map,
    path,
    pipe,
    uniq,
    trim,
    split,
    head,
    replace,
    reduce,
    converge,
    append, last, sort, concat, propOr, __, isNotEmpty, isEmpty
} from "ramda";
import {decodeHtml} from "./api";

export const createListItem = curryN(2, (getColor, item) => <List.Item style={{padding: '0px'}}>
    <MarineLifeMenu item={item} color={getColor(item)}/>
</List.Item>)

const trimSP = pipe(
    split(' sp.'),
    head
)

const trimSyn = pipe(
    split(' (syn.'),
    head
)

const extractSyn = pipe(
    split(' (syn. '),
    last,
    replace(')', '')
)

export const getGenus = pipe(
    map(path(['acf', 'genus'])),
    map(trim),
    map(trimSP),
    filter(identity),
    uniq
)

export const notGeneralDescription = pipe(path(['acf', 'general_description']), isEmpty)

export const getSpecies = pipe(
    filter(notGeneralDescription),
    map(path(['title', 'rendered'])),
    map(decodeHtml),
    map(trim),
    map(trimSP),
    converge(concat, [map(trimSyn), map(extractSyn)]),
    filter(identity),
    uniq,
    sort((a, b) => length(b) - length(a))
)

const italicizeData = (text, data) => replace(data, `<i>${data}</i>`, text)
export const italicize = curryN(2, (lst, text) => {
    return <span dangerouslySetInnerHTML={{__html: reduce(italicizeData, text, lst)}}/>
})

const getAcfProps = curryN(2, (props, marineLife) => map(propOr('', __, marineLife.acf), props))
export const getImages = getAcfProps(['main_photo', 'photo2', 'photo3', 'photo4', 'photo5', 'photo6'])
export const getTexts = getAcfProps(['interesting_text', 'photo_2_text', 'photo_3_text', 'photo_4_text', 'photo_5_text', 'photo_6_text'])

