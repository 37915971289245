import {Space} from 'antd'
import './MarineGroup.css'
import {useState} from 'react'
import {useStoreState} from 'easy-peasy'
import {assoc, pathEq, pipe, prop, replace, toLower} from 'ramda'
import {Link} from 'react-router-dom'
import {basePath} from '../lib/api'
import {getColor} from "../lib/marine-type";

export const MarineGroup = ({item, onSelect}) => {
    const [open, setOpen] = useState(false)
    const display = useStoreState(prop('display'))

    const onClick = () => {
        onSelect(item, !open)
        setOpen(!open)
    }

    const color = getColor(item)

    const style = {
        background: color,
        width: '100%',
        height: '100%',
        padding: '5px',
        borderLeftWidth: '6px',
        borderLeftStyle: 'solid',
    }

    const colorClass = 'marine-group-' + pipe(prop('name'), toLower, replace(' ', '-'))(item)

    return <>
        <Link to={basePath + '/marine-type/' + item.slug} className={'marine-group marine-group-link marine-group-default ' + colorClass}
              style={assoc('borderColor', pathEq(item.id, ['id', 'id'], display) ? 'white' : color, style)}
              onClick={onClick}>
            <Space direction={'vertical'}>
                <div className={'parent'} dangerouslySetInnerHTML={{__html: item.parentName}}/>
                <div className={'sub-group'} dangerouslySetInnerHTML={{__html: item.name}}/>
            </Space>
        </Link>
    </>

}