import {useStoreState} from "easy-peasy";
import {max, pathOr, prop} from "ramda";
import {Image, Space} from "antd";
import {
    RotateLeftOutlined,
    RotateRightOutlined,
    SwapOutlined,
    ZoomInOutlined,
    ZoomOutOutlined
} from "@ant-design/icons";
import useWindowDimensions from "../hooks/useWindowDimension";

const wrapInParagraph = text => {
    return text.startsWith('<p') ? text : '<p>' + text + '</p>'
}
export const MarineLifeImage = ({id, text, maxWidth=300}) => {

    const imagesById = useStoreState( prop( 'mediaById' ) )
    const {width} = useWindowDimensions()

    const imageWidth = max( width/4, maxWidth)

    const imageAspectRatio = 4/3

    const imageStyle = {
        border: '3px solid black',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        margin: '20px',
        objectFit: 'cover',
        height: imageWidth / imageAspectRatio + 'px',
        width: imageWidth + 'px',
    }

    const textStyle = {
        width: imageWidth+'px',
        padding:'10px',
        borderRadius:'4px'
    }

    const copyrightStyle = {
        marginTop: '-20px',
        marginLeft: '20px',
        fontSize: '12px',
        textAlign: 'right',
        width: imageWidth + 'px',
    }

    const copyright = pathOr('', [id, 'meta', '_custom_copyright'], imagesById )

    return <>
        {imagesById[id] && <Image
            className={'center'}
            style={imageStyle}
            src={imagesById[id]['source_url']}
            preview={{
                maskClassName: 'marine-image-mask',
                toolbarRender: (_, {
                    transform: {scale},
                    actions: {onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn},
                },) => (<Space size={16} className="toolbar-wrapper">
                    <SwapOutlined rotate={90} onClick={onFlipY}/>
                    <SwapOutlined onClick={onFlipX}/>
                    <RotateLeftOutlined onClick={onRotateLeft}/>
                    <RotateRightOutlined onClick={onRotateRight}/>
                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut}/>
                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn}/>
                </Space>),
            }}
        />}
        { copyright && <div style={copyrightStyle}>{copyright}</div>}
        { text && <div className={'center marine-group'} style={textStyle} dangerouslySetInnerHTML={{__html: wrapInParagraph(text)}} /> }

    </>

}