import { identity, map } from 'ramda'
import { List } from 'antd'
import { MarineSubGroup } from './MarineSubGroup'

export const MarineSubGroupList = ({ parent, items, color }) => {

  return map( (child) => {
    return <List.Item style={{ padding: '0px' }}>
      <MarineSubGroup parent={parent} item={child} onSelect={identity} parentColor={color}/>
    </List.Item>
  }, items )
}
