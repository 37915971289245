import {Flex} from 'antd'
import {useStoreActions, useStoreState} from 'easy-peasy'
import {assoc, prop, propEq} from 'ramda'
import {Link} from 'react-router-dom'
import {basePath} from '../lib/api'
import {backgroundColor, textColor} from "../lib/settings";
import {getThumbnailUrl} from "../lib/thumbnail";
import {italicize} from "../lib/marine-life";

export const MarineLifeMenu = ({item, color}) => {

    const imagesById = useStoreState(prop('mediaById'))

    const setDisplay = useStoreActions(prop('setDisplay'))
    const display = useStoreState(prop('display'))

    const species = useStoreState(prop('species'))

    const style = {
        width: '100%',
        background: backgroundColor,
        borderLeftWidth: '6px',
        borderLeftStyle: 'solid'
    }

    const thumbnailUrl = getThumbnailUrl(item, imagesById)
    return <Link
        to={basePath + '/marine-life/' + item.slug}
        style={assoc('borderColor', propEq(item.id, 'id', display) ? color : backgroundColor, style)}
        onClick={() => setDisplay({type: 'marine-life', id: item.id})}
    >
        <Flex horizontal>
            <div style={{padding: '5px'}}>
                {thumbnailUrl && <img
                    alt={item.title.rendered}
                    height={80}
                    src={thumbnailUrl}
                    style={{border: '2px solid black'}}
                />}
            </div>
            <div className={'marine-group marine-group-link'} style={{
                width: '100%',
                padding: '10px',
                verticalAlign: 'middle',
                fontSize: '14px',
                color: textColor
            }}>{italicize(species, item.title.rendered)}</div>
        </Flex>
    </Link>
}