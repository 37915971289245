import { Flex, Space } from 'antd'
import './MarineGroup.css'
import { useState } from 'react'
import { RightSquareOutlined } from '@ant-design/icons'
import { MarineLifeList } from './MarineLifeList'
import { useStoreActions, useStoreState } from 'easy-peasy'
import {assoc, pathEq, pipe, prop, replace, toLower} from 'ramda'
import { Link } from 'react-router-dom'
import { basePath } from '../lib/api'

export const MarineSubGroup = ({parent, item, onSelect, parentColor }) => {
  const [isOpen, setIsOpen] = useState( false )

  const setDisplay = useStoreActions( prop( 'setDisplay' ) )
  const display = useStoreState( prop( 'display' ) )

  const onClick = () => {
    setDisplay( { type: 'marine-type', id: item } )
    setIsOpen( !isOpen )
  }
  const style = {
    background: parentColor,
    height: '100%',
    padding: '5px',
    paddingLeft: '10px',
    borderLeftWidth: '6px',
    borderLeftStyle: 'solid'
  }

  const colorClass = 'marine-group-' + pipe(prop('name'), toLower, replace(' ', '-'))(parent)

  return <>
    <Flex vertical style={{ width: '100%' }}>
      <Link to={basePath + '/marine-type/' + item.slug} className={'marine-group marine-group-link marine-group-default ' + colorClass}
            style={assoc( 'borderColor', pathEq( item.id, ['id', 'id'], display ) ? 'white' : parentColor, style )}
            onClick={onClick}>
        <Space direction={'horizontal'}>
          <RightSquareOutlined style={{ background: item.acf.color }}/>
          <div className={'sub-group'} dangerouslySetInnerHTML={{ __html: item.name }}/>
        </Space>
      </Link>
      {isOpen && <MarineLifeList marineType={item.id} color={parentColor}/>}
    </Flex>
  </>

}