import {useStoreState} from 'easy-peasy'
import './MarineLifeContent.css'
import {filter, map, prop, propOr, __, pathOr, mergeRight, zip, last, startsWith} from 'ramda'
import {orderByParent} from '../lib/marine-type'
import {Space, Row, Col} from 'antd'
import {MarineLifeTaxonomy} from './MarineLifeTaxonomy'
import {MarineLifeHeading} from './MarineLifeHeading'
import {useParams} from 'react-router-dom'
import {MarineLifeImage} from "./MarineLifeImage";
import {canEditPosts, getPostEditLink} from "../lib/api";
import {getImages, getTexts, italicize} from "../lib/marine-life";
import {MarineLifeGallery} from "./MarineLifeGallery";
import {useEffect} from "react";
import {sendPageView} from "../lib/google-analytics";


export const MarineLifeContent = () => {

    const {type} = useParams()

    const marineLifeBySlug = useStoreState(prop('marineLifeBySlug'))
    const marineTypeById = useStoreState(prop('marineTypeById'))
    const species = useStoreState(prop('species'))

    const marineLife = prop(type, marineLifeBySlug)

    useEffect(() => {
        sendPageView();
    }, [type])


    if (!marineLife) {
        return <></>
    }

    const marineTypes = orderByParent(map(prop(__, marineTypeById), marineLife['marine-type']))

    const getMarineTypeColor = index => pathOr('', [index, 'acf', 'color'], marineTypes)

    const getMarineTypeName = index => pathOr('', [index, 'name'], marineTypes)

    const color = getMarineTypeColor(1)

    const marineType = last(marineTypes)
    const taxonomy = {
        'kingdom': pathOr('', ['acf', 'kingdom'], marineType),
        'phylum': pathOr('', ['acf', 'phylum'], marineType),
        'class': pathOr('', ['acf', 'class'], marineType),
        'order': pathOr('', ['acf', 'order'], marineType),
    }

    const titleStyle = {
        textAlign: 'center', color: 'gray', fontSize: '20px', marginBottom: 0,
    };

    return <>
        <MarineLifeHeading
            color={color}
            kingdom={getMarineTypeName(0)}
            group={getMarineTypeName(1)}
            subgroup={getMarineTypeName(2)}
            subcolor={getMarineTypeColor(2)}
        />

        <div className="marine-group" style={{
            display: 'flex',
            backgroundColor: 'white'
        }}>
            <div style={{flex: '80%'}}>
                <Space
                    direction="vertical"
                    size="middle"
                    style={{
                        display: 'flex',
                    }}

                >
                    <h1 style={titleStyle}>{italicize(species, marineLife.title.rendered)}</h1>

                    {marineLife.acf['common_name'] && <h2 style={{
                        textAlign: 'center', color: 'gray', fontSize: '16px', marginTop: 0, marginBottom: 0
                    }}>{'Common Name: ' + marineLife.acf['common_name']}</h2>}

                    {canEditPosts && <h4 style={{textAlign: 'center'}}><a href={getPostEditLink(marineLife.id)}>Edit</a></h4>}

                    <div dangerouslySetInnerHTML={{__html: marineLife.content.rendered}}
                         style={{padding: '10px', marginTop: '-20px', color: 'black'}}/>

                    <MarineLifeGallery marineLife={marineLife}/>

                </Space>

            </div>
            <MarineLifeTaxonomy
                marineLife={mergeRight(taxonomy, marineLife)}
                color={color}
            />

        </div>
    </>
}