import { Avatar, Space } from 'antd'
import {pipe, prop, replace, toLower} from "ramda";

export const MarineLifeHeading = ({ color, kingdom, group, subgroup, subcolor }) => {

  const colorClass = 'marine-group-' + pipe(toLower, replace(' ', '-'))(group)

  subcolor = subcolor ? subcolor : color

  return <>
    <div className={"heading row marine-group marine-group-default " + colorClass} style={{ background: color, display: 'flex', paddingBottom: '-10px' }}>
      <div style={{ flex: '3%' }}>
      </div>
      <div style={{ flex: '70%', paddingTop: 10 }}>
        {kingdom}
        <h4 dangerouslySetInnerHTML={{ __html: group }}/>
      </div>
      <div style={{ flex: '20%', textAlign: 'center', paddingTop: 10 }}>
        {subgroup && <Space direction={'vertical'}>
          <Avatar style={{ marginTop: '4px', backgroundColor: subcolor, verticalAlign: 'middle' }}
                  size="large"/>
          <h4 dangerouslySetInnerHTML={{ __html: subgroup }} style={{ marginTop: '-2px' }}/>
        </Space>}
      </div>
    </div>
  </>

}
