import {action, computed, createStore, thunk} from 'easy-peasy'
import MiniSearch from 'minisearch'
import {concat, filter, head, indexBy, prop, propEq, map, path, identity, uniq} from 'ramda'
import {paginatedLoad, siteInfo} from './api'
import {getGenus, getSpecies} from "./marine-life";

const string = require('string-sanitizer')


const getSlug = item => {
    item.slug = string.sanitize.addDash(item.title.rendered).toLowerCase()
    return item
}

export const store = createStore({

    search: {
        engine: new MiniSearch({
            fields: ['title.rendered', 'content.rendered', 'acf.interesting_text', 'acf.photo_2_text'], // fields to index for full-text search
            storeFields: ['id', 'title'], // fields to return with search results
            extractField: (document, fieldName) => {
                // Access nested fields
                return fieldName.split('.').reduce((doc, key) => doc && doc[key], document)
            }
        }),
        results: [],
        suggestions: [],
        value: '',

    },
    setValue: action((state, payload) => {
        state.search.value = payload
        state.search.results = state.search.engine.search(payload, {fuzzy: 0.2})
        state.search.suggestions = state.search.engine.autoSuggest(payload, {fuzzy: 0.2})
    }),

    marineLife: [],
    marineLifeById: computed((state) => indexBy(prop('id'), state.marineLife)),
    marineLifeBySlug: computed((state) => indexBy(prop('slug'), state.marineLife)),
    addMarineLife: action((state, payload) => {
        state.marineLife = concat(state.marineLife, map(getSlug, payload))
        state.search.engine.addAll(payload)
    }),
    loadMarineLife: thunk(async (actions, payload, helpers) => {
        await paginatedLoad('/wp/v2/marine-life', actions.addMarineLife)
    }),

    marineType: [],
    marineTypeById: computed((state) => indexBy(prop('id'), state.marineType)),
    marineTypeBySlug: computed((state) => indexBy(prop('slug'), state.marineType)),
    addMarineType: action((state, payload) => {
        state.marineType = concat(state.marineType, payload)
    }),
    loadMarineType: thunk(async (actions, payload, helpers) => {
        await paginatedLoad('/wp/v2/marine-type', actions.addMarineType)
    }),

    media: [],
    mediaById: computed((state) => indexBy(prop('id'), state.media)),
    addMedia: action((state, payload) => {
        state.media = concat(state.media, payload)
    }),
    loadMedia: thunk(async (actions, payload, helpers) => {
        await paginatedLoad('/wp/v2/media', actions.addMedia)
    }),

    pages: [],
    pagesById: computed((state) => indexBy(prop('id'), state.pages)),
    addPages: action((state, payload) => {
        state.pages = concat(state.pages, payload)
    }),
    loadPages: thunk(async (actions, payload, helpers) => {
        await paginatedLoad('/wp/v2/pages', actions.addPages)
        actions.setDisplay({type: 'page', id: head(filter(propEq('title', 'slug'), helpers.getState().pages))})
    }),

    display: {},
    setDisplay: action((state, payload) => {
        state.display = payload
    }),

    title: '',
    tagLine: '',
    loadSiteInfo: thunk(async (actions, payload, helpers) => {
        const data = await siteInfo()
        actions.setTitle(prop('name', data))
        actions.setTagLine(prop('description', data))
    }),
    setTitle: action((state, payload) => {
        state.title = payload
    }),
    setTagLine: action((state, payload) => {
        state.tagLine = payload
    }),
    isIntroPage: computed((state) => state.display.type === 'page' && state.display.id.slug === 'title'),

    species: computed( (state) => getSpecies( state.marineLife)),

    genus: computed( (state) => getGenus( state.marineLife)),

})