import {filter, map, prop, __, always} from 'ramda'
import {List} from 'antd'
import {useStoreState} from 'easy-peasy'
import {isGrandchild} from "../lib/marine-type";
import {byGenusThenTitle as sortByGenusThenTitle, byTitle} from "../lib/sort";
import {createListItem} from "../lib/marine-life";
import Identity from "crocks/Identity"

export const MarineLifeList = ({marineType, color}) => {

    const marineLife = useStoreState(prop('marineLife'))
    const marineTypeById = useStoreState(prop('marineTypeById'))

    const byType = item => isGrandchild(marineType, map(prop(__, marineTypeById), item['marine-type']))

    return Identity(marineLife)
        .map(filter(byType))
        .map(byTitle)
        .map(map(createListItem(always(color))))
        .map(item => <List>{item}</List>)
        .valueOf()

}
