import {pathOr, prop, propOr, flip} from "ramda";

const propOf = flip(prop)

export const getThumbnailUrlFromImage = image => {
    const fallbackUrl = propOr(null, ['source_url'], image)
    return pathOr(fallbackUrl, ['media_details', 'sizes', 'thumbnail', 'source_url'], image)
}

export const getThumbnailUrl = (item, imagesById) => {
    return ['thumbnail', 'main_photo']
        .map(propOf(item.acf))
        .map(propOf(imagesById))
        .reduce((url, image) => url || getThumbnailUrlFromImage(image), null)
}
