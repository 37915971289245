import { useStoreActions, useStoreState } from 'easy-peasy'
import { prop, filter, propEq, head, assoc } from 'ramda'
import { Link } from 'react-router-dom'
import {basePath} from "../lib/api";
import {textColor} from "../lib/settings";

export const PageMenu = ({ name }) => {

  const pages = useStoreState( prop( 'pages' ) )
  const setDisplay = useStoreActions( prop( 'setDisplay' ) )
  const display = useStoreState( prop( 'display' ) )

  const thePage = head( filter( propEq( name, 'slug' ), pages ) )
  const color = thePage ? thePage.acf['color'] : '#ffffff'

  const style = {
    color: textColor,
    height: 'calc(100%)',
    width: '100%',
    padding: '5px',
    borderLeftWidth: '6px',
    borderLeftStyle: 'solid',
    backgroundImage: 'linear-gradient(to left, ' + color + '90, ' + color + ')'
  }

  const link = name === 'title' ? '/' : '/' + name

  return <Link
    to={basePath + link}
    className={'marine-group'}
    style={assoc( 'borderColor', propEq( thePage, 'id', display ) ? 'white' : color, style )}
    onClick={() => setDisplay( { type: 'page', id: thePage } )}
  >
    <div>
      <b>
        {thePage ? thePage.title.rendered : ''}
      </b>
    </div>
  </Link>
}