import React from 'react'
import { Layout } from 'antd'
import { MarineTypesList } from './MarineTypesList'

const { Sider } = Layout

const siderStyle = {
  lineHeight: '150px',
  color: '#fff',
  backgroundColor: '#ffffff',
  overflowY: 'scroll'

}

export const Menu = ({ width }) => {

  return <Sider width={width} style={siderStyle}>
    <MarineTypesList/>
  </Sider>

}

