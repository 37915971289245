import { AutoComplete, Input } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { prop, path, map, pipe } from 'ramda'

export const Search = () => {
  const setSearch = useStoreActions( prop( 'setValue' ) )
  const autoSuggest = useStoreState( path( ['search', 'suggestions'] ) )
  const options = map(
    pipe(
      prop( 'suggestion' ),
      v => {return {value:v, label:v}}
    ),
    autoSuggest )

  return <div style={{width: '100%'}}>
    <AutoComplete
      popupMatchSelectWidth={252}
      onSearch={setSearch}
      style={{
        width: '100%',
        padding: 5,
        height: '100%'
      }}
      size="large"
      options={options}
      onSelect={setSearch}
    >
      <Input.Search size="large" placeholder="Search" enterButton allowClear={true}/>
    </AutoComplete>
  </div>
}