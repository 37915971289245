import {MarineLifeHeading} from './MarineLifeHeading'
import {useParams} from 'react-router-dom'
import {useStoreState} from 'easy-peasy'
import {pathOr, prop, propOr, replace} from 'ramda'
import {getHierachy} from '../lib/marine-type'
import {MarineLifeImage} from "./MarineLifeImage";
import {useEffect} from "react";
import {sendPageView} from "../lib/google-analytics";


export const MarineTypeContent = () => {

    const {type} = useParams()
    const marineTypeBySlug = useStoreState(prop('marineTypeBySlug'))
    const marineTypeById = useStoreState(prop('marineTypeById'))

    const item = propOr(null, type, marineTypeBySlug)

    const marineTypeList = item ? getHierachy(item, marineTypeById) : []

    const color = pathOr('white', [1, 'acf', 'color'], marineTypeList)

    useEffect(() => {
        sendPageView()
    }, [type])

    return item ? <>
            <MarineLifeHeading
                color={color}
                kingdom={pathOr('', [0, 'name'], marineTypeList)}
                group={pathOr('', [1, 'name'], marineTypeList)}
                subgroup={pathOr('', [2, 'name'], marineTypeList)}
                subcolor={pathOr('', [2, 'acf', 'color'], marineTypeList)}
            />

            {item ? <div className={'marine-group'} style={{
                padding: '10px',
                display: 'block',
                height: '100%',
                backgroundColor: 'white'
            }}>
                <div style={{float: 'left'}}>
                    <MarineLifeImage id={item.acf['main_image']} maxWidth={500}/>
                </div>
                <p dangerouslySetInnerHTML={{__html: replace(/\r\n/g, '<br/>', item.acf['information'])}} style={{textAlign: 'justify'}}/>
            </div> : ''}

        </>
        : <></>;
}