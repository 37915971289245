import {path, pathOr, filter, addIndex, map, pipe, identity, propOr, prop, curryN} from 'ramda'
import { Table } from 'antd'
import {decodeHtml} from "../lib/api";
import {useStoreState} from "easy-peasy";
import {italicize, notGeneralDescription} from "../lib/marine-life";

export const MarineLifeTaxonomy = ({ marineLife, color }) => {

  const genus = useStoreState(prop('genus'))
  const species = useStoreState(prop('species'))

  const italic = text => <i>{text}</i>
  const fields = [
    ['Kingdom', marineLife.acf.kingdom ? [ 'acf', 'kingdom'] : ['kingdom']],
    ['Phylum', marineLife.acf.phylum ? [ 'acf', 'phylum'] : ['phylum']],
    ['Class', marineLife.acf.class ? [ 'acf', 'class'] : ['class']],
    ['Order', marineLife.acf.order ? [ 'acf', 'order'] : ['order']],
    ['Family', ['acf', 'family']],
    ['Genus', ['acf', 'genus'], italicize(genus)],
  ]

  if (notGeneralDescription(marineLife)) {
    fields.push(['Species', ['title', 'rendered'], italicize(species)])
  }

  const hasField = field => pathOr( '', field[1], marineLife )
  const makeData = (field, idx) => {
    return {
      key: idx,
      name: field[0],
      value: propOr( identity, 2, field )( path( field[1], marineLife ) )
    }
  }

  const dataSource = pipe(
    filter( hasField ),
    addIndex( map )( makeData )
  )( fields )

  const columns = [
    {
      dataIndex: 'name',
      key: 'name'
    },
    {
      dataIndex: 'value',
      key: 'value',
    }

  ]
  return <div style={{ flex: '20%' }}>
    <div style={{ background: color, marginTop: '20px', padding: '5px', fontSize: '12px' }}>
      <Table dataSource={dataSource} columns={columns} pagination={false} showHeader={false} size={'small'}/>
    </div>
  </div>
}