import {Col, Row, Space} from "antd";
import {MarineLifeImage} from "./MarineLifeImage";
import {map, zip} from "ramda";
import {getImages, getTexts} from "../lib/marine-life";

export const MarineLifeGallery = ({marineLife}) => {

    const images = getImages(marineLife)
    const texts = getTexts(marineLife)

    return <Row>
        {map((imageData) => <Col
            md={{flex: '100%'}}
            lg={{flex: '100%'}}
            xl={{flex: '50%'}}
        >
            <div>

                <Space direction={'vertical'}>
                    <MarineLifeImage id={imageData[0]} text={imageData[1]}/>
                </Space>
            </div>
        </Col>, zip(images, texts))}
    </Row>
}
