import {ascend, ascendNatural, either, path, pipe, sort, sortBy, sortWith, toLower} from "ramda";

const getGenus = path(['acf', 'genus'])
const getTitle = path(['title', 'rendered'])

export const byGenus = sortBy(pipe(either(getTitle, getGenus), toLower))

//export const byTitle = sortBy(pipe(getTitle, toLower))


export const byGenusThenTitle = sortWith([ascendNatural('en', getGenus), ascendNatural('en', getTitle)])

export const byTitle = sort(ascendNatural('en', getTitle))
