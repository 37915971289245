import {List} from 'antd'
import {__, map, path, pipe, prop} from 'ramda'
import {useStoreState} from 'easy-peasy'
import {orderByParent} from '../lib/marine-type'
import {byGenus as sortByGenus} from "../lib/sort";
import {createListItem} from "../lib/marine-life";
import Identity from "crocks/Identity";

export const SearchResults = () => {

    const searchResults = useStoreState(path(['search', 'results']))
    const marineLifeById = useStoreState(prop('marineLifeById'))
    const marineTypeById = useStoreState(prop('marineTypeById'))

    const getMarineTypes = pipe(prop('marine-type'), map(prop(__, marineTypeById)), orderByParent)

    const getColor = pipe(getMarineTypes, path([1, 'acf', 'color']))

    return Identity(searchResults)
        .map(sortByGenus)
        .map(map(prop('id')))
        .map(map(prop(__, marineLifeById)))
        .map(map(createListItem(getColor)))
        .map(item => <List>{item}</List>)
        .valueOf()
}
